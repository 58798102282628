<template>
    <panel-section id="payment">
        <h1>Make a Payment</h1>

        <ol>
            <li>
                Enter a short description of what you're paying for in the first
                text field
            </li>
            <li>
                Enter a dollar amount (USD) without the dollar symbol ($) into
                the second text field
            </li>
            <li>Click the "Submit Payment" button</li>
            <li>
                Sign into your PayPal account or choose the "Don't have a PayPal
                account?" guest option
            </li>
        </ol>

        <form
            @submit="submitForm"
            name="_xclick"
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
        >
            <input type="hidden" name="cmd" value="_xclick" />
            <input
                type="hidden"
                name="business"
                value="nicole@nicolesmith.dev"
            />
            <input type="hidden" name="currency_code" value="USD" />

            <div class="form-control" :class="{ invalid: !paymentFor.isValid }">
                <label for="service"
                    >Payment For <span class="req">*</span></label
                >
                <input
                    type="text"
                    id="service"
                    name="item_name"
                    v-model.trim="paymentFor.val"
                    @blur="clearValidity('paymentFor')"
                />
                <p v-if="!paymentFor.isValid" class="desc">
                    Please enter a short description of what you're paying for.
                </p>
            </div>
            <div class="form-control" :class="{ invalid: !amount.isValid }">
                <label for="amount">Amount <span class="req">*</span></label>
                <input
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="100.00"
                    v-model.trim="amount.val"
                    @blur="clearValidity('amount')"
                />
                <p v-if="!amount.isValid" class="desc">
                    Please enter a dollar amount.
                </p>
            </div>
            <button>Submit Payment</button>
        </form>
    </panel-section>
</template>

<script>
export default {
    head() {
        return {
            title: 'Make a Payment',
            meta: [{ name: 'robots', content: 'noindex,nofollow' }],
        };
    },
    data() {
        return {
            formIsValid: true,
            paymentFor: {
                val: '',
                isValid: true,
            },
            amount: {
                val: '',
                isValid: true,
            },
        };
    },
    methods: {
        clearValidity(input) {
            this[input].isValid = true;
        },
        validateForm() {
            this.formIsValid = true;
            if (this.paymentFor.val === '') {
                this.paymentFor.isValid = false;
                this.formIsValid = false;
            }
            if (this.amount.val === '') {
                this.amount.isValid = false;
                this.formIsValid = false;
            }
        },
        submitForm(e) {
            e.preventDefault();
            this.validateForm();
            if (!this.formIsValid) {
                return;
            }
            e.target.submit();
        },
    },
};
</script>
