<template>
    <portfolio-single :items="items">
        <template v-slot:image>
            <a href="http://www.mightymichiganbulldogges.com/"
                ><img
                    src="../../../assets/images/mighty-michigan-bulldogges.png"
                    width="1411"
                    height="931"
            /></a>
        </template>
        <template v-slot:title>Local Dog Breeder</template>
        <template v-slot:link>
            <a href="http://www.mightymichiganbulldogges.com/"
                >mightymichiganbulldogges.com</a
            >
        </template>
    </portfolio-single>
</template>

<script>
export default {
    data() {
        return {
            items: [
                'Custom designed',
                'Powered by WordPress',
                'Responsive / mobile friendly',
            ],
        };
    },
};
</script>
