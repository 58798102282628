<template>
    <portfolio-single :items="items">
        <template v-slot:image>
            <a href="https://breanne.nicolesmith.dev/">
                <img
                    src="../../../assets/images/iron-owl-designs.png"
                    alt="Iron Owl Designs"
                    width="1411"
                    height="931"
                />
            </a>
        </template>
        <template v-slot:title>Graphic Designer</template>
        <template>
            <p>
                I collaborated with a graphic designer to develop a custom
                WordPress theme to showcase her services and portfolio.
            </p>
        </template>
        <template v-slot:link>
            <a href="https://breanne.nicolesmith.dev/">breanne.nicolesmith.dev</a>
        </template>
    </portfolio-single>
</template>

<script>
export default {
    data() {
        return {
            items: [
                'Custom designed',
                'Powered by WordPress',
                'Responsive / mobile friendly',
            ],
        };
    },
};
</script>
