<template>
    <portfolio-single :items="items">
        <template v-slot:image>
            <a href="https://www.infusepdr.com/"
                ><img
                    src="../../../assets/images/infused-pr.png"
                    width="1411"
                    height="931"
            /></a>
        </template>
        <template v-slot:title>Public Relations &amp; Event Planning</template>
        <template v-slot:link>
            <a href="https://www.infusedpr.com/">infusedpr.com</a>
        </template>
    </portfolio-single>
</template>

<script>
export default {
    data() {
        return {
            items: [
                'Custom designed',
                'Powered by WordPress',
                'Responsive / mobile friendly',
            ],
        };
    },
};
</script>
