<template>
    <div class="row">
        <div class="col col-1third">
            <img
                src="../../assets/images/nicole-smith-family-2.jpg"
                alt="Nicole Smith family"
                width="600"
                height="500"
            />
        </div>

        <div class="col col-2third col-last">
            <h2>About Me</h2>

            <p>
                My name is Nicole Smith (née Boyd) whose favorite color is
                <em>pink</em>. I am a full stack web developer specializing in
                WordPess and Vue. My passion for web development started at a
                young age. I was, and still am, fascinated by how code can bring
                a website to life.
            </p>

            <p>
                With 13 years of professional experience, my primary skillset
                includes Vue, HTML, SCSS, PHP, and MySQL. I am currently
                learning Laravel, and also have some experience with React and
                Express.
            </p>

            <p>
                My husband and I are proud parents to twin girls, two bulldogs,
                and a cat. Hockeytown is where we call home.
            </p>
        </div>
    </div>
</template>
