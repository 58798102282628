<template>
    <portfolio-single :items="items">
        <template v-slot:image>
            <a href="https://eattherainbow.recipes/"
                ><img
                    src="../../../assets/images/eat-the-rainbow.png"
                    alt="Eat The Rainbow"
                    width="1411"
                    height="931"
            /></a>
        </template>
        <template v-slot:title>Recipes Web App</template>
        <template>
            <p>
                My first and on-going MERN (MongoDB, Express, React, Node)
                project, Eat The Rainbow is a collection of recipes based on a
                popular color-coded nutrition program. This reactive web
                application allows users to filter recipes by category,
                container color, dietary needs, and/or source.
            </p>
        </template>
        <template v-slot:link>
            <a href="https://etr.nicolesmith.dev/">eattherainbow.recipes</a>
        </template>
    </portfolio-single>
</template>

<script>
export default {
    data() {
        return {
            items: ['Custom React programming', 'Responsive / mobile friendly'],
        };
    },
};
</script>
