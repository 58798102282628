<template>
    <div>
        <panel-section id="portfolio">
            <h1 class="text-center">Websites I Have Built</h1>

            <p class="text-center">
                My passion for web development started at a young age. I was,
                and still am, fascinated by how code can bring a website to
                life. With 13 years of professional experience, my primary
                skillset includes Vue, HTML, SCSS, PHP, and MySQL with a focus
                on custom WordPress theme and plugin development. I am currently
                learning Laravel, and also have some experience with React and
                Express.
            </p>

            <p id="skills" class="text-center">
                <img
                    v-for="skill in skills"
                    :key="skill"
                    :src="require(`../../assets/images/${skill}.svg`)"
                    :alt="skill"
                    width="90"
                    height="90"
                />
            </p>
        </panel-section>
        <panel-section v-for="(example, i) in examples" :key="i">
            <component :is="example" />
        </panel-section>
    </div>
</template>

<script>
import BBA from './portfolio/BBA';
import RichmondChoir from './portfolio/RichmondChoir';
import EatTheRainbow from './portfolio/EatTheRainbow';
import InfusedPR from './portfolio/InfusedPR';
import IronOwlDesigns from './portfolio/IronOwlDesigns';
import MMB from './portfolio/MMB';
import TranquilCore from './portfolio/TranquilCore';

export default {
    head() {
        return {
            title: 'Portfolio',
            meta: [{ name: 'robots', content: 'noindex,nofollow' }],
        };
    },
    components: {
        BBA,
        RichmondChoir,
        EatTheRainbow,
        InfusedPR,
        IronOwlDesigns,
        MMB,
        TranquilCore,
    },
    computed: {
        examples() {
            return [
                'MMB',
                'IronOwlDesigns',
                'EatTheRainbow',
                'BBA',
                'Choir',
                'TranquilCore',
                'InfusedPR',
            ];
        },
        skills() {
            return [
                'wordpress',
                'vue',
                'html',
                'css',
                'javascript',
                'sass',
                'php',
                'mysql',
                'laravel',
                'react',
            ];
        },
    },
};
</script>

<style lang="scss">
#portfolio {
    img {
        max-height: 90px;
        max-width: 90px;
    }
    #skills {
        background-color: transparent;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 1rem;
        justify-content: center;
    }
}
.portfolio {
    ul {
        padding-left: 0;
        &.column-count-2 {
            @include respond-to('large') {
                @include column-count(2);
            }
        }
        li {
            list-style: none;
        }
    }
    .fa {
        margin-right: 0.25rem;
    }
}
</style>
