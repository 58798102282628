<template>
    <div class="row portfolio">
        <div class="col col-1half">
            <slot name="image"></slot>
        </div>

        <div class="col col-1half col-last">
            <h3><slot name="title"></slot></h3>
            <slot></slot>
            <ul v-if="items" :class="listClasses">
                <li v-for="(item, i) in items" :key="i">
                    <i class="fa fa-check-circle"></i>
                    <slot name="item">{{ item }}</slot>
                </li>
            </ul>
            <p v-if="$slots.link">
                <i class="fa fa-link"></i>
                <slot name="link"></slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['items'],
    computed: {
        listClasses() {
            return this.items && this.items.length >= 6 ? 'column-count-2' : '';
        },
    },
};
</script>
