<template>
    <portfolio-single>
        <template v-slot:image>
            <a href="https://www.richmondcommunitychoir.com/"
                ><img
                    src="../../../assets/images/richmond-community-choir.png"
                    width="1411"
                    height="931"
            /></a>
        </template>
        <template v-slot:title>Local Choir</template>
        <template>
            <p>
                The previous website was built in Weebly. I custom designed this
                WordPress-powered site for the Richmond Community Choir so they
                could still login to a backend and edit the website themselves
                if they desired.
            </p>
        </template>
        <template v-slot:link>
            <a href="https://www.richmondcommunitychoir.com/"
                >richmondcommunitychoir.com</a
            >
        </template>
    </portfolio-single>
</template>
