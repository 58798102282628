<template>
    <portfolio-single :items="items">
        <template v-slot:image>
            <a href="https://www.tranquilcore.com/"
                ><img
                    src="../../../assets/images/tranquil-core.png"
                    width="1411"
                    height="931"
            /></a>
        </template>
        <template v-slot:title>Therapist</template>
        <template v-slot:link>
            <a href="https://www.tranquilcore.com/">tranquilcore.com</a>
        </template>
    </portfolio-single>
</template>

<script>
export default {
    data() {
        return {
            items: [
                'Custom designed',
                'Powered by WordPress',
                'Responsive / mobile friendly',
            ],
        };
    },
};
</script>
